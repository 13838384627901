import React from "react";

const HeaderBar = () => {
  return (
    <div className="headerBar-wrapper">
      <div>phone number address</div>
      <div>social media icons</div>
    </div>
  );
};

export default HeaderBar;
