import React from "react";
import brands from "../images/brands.png";

const Collections = () => {
  return (
    <>
      <div className="collection-wrapper">
        <img src={brands} alt="brands offered" />
      </div>
    </>
  );
};

export default Collections;
